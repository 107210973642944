
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.esmp-sidebar {
  z-index: 11;

  .esmp-dropdown {
    display: unset;
  }
}

.esmp-sidebar-link {
  .esmp-user-avatar {
    width: 24px;
    height: 24px;
  }
}

.sidebar-portal-name {
  display: flex;
  align-items: center;

  &__logo {
    display: inline-block;
    margin-right: 10px;
    width: auto;
    height: auto;
    max-width: 20px;
    max-height: 20px;
  }

  span {
    text-transform: uppercase;
  }
}

.sidebar {
  $sidebarClass: &;
  $sidebar-item-draggable-element-width: 40px;

  &__settings-button {
    color: inherit;

    &:hover {
      color: $primary-color-hover;
    }
  }

  &__item {
    transition:
      width $base-animation,
      margin-left $base-animation,
      background-color $base-animation,
      color $base-animation;

    &:before {
      content: '=';
      position: absolute;
      top: 0;
      left: -$sidebar-item-draggable-element-width;
      width: $sidebar-item-draggable-element-width;
      height: $esmp-sidebar-link-height;
      line-height: $esmp-sidebar-link-height;
      background: inherit;
      font-size: 26px;
      font-weight: 400;
      text-align: center;
      visibility: hidden;
      opacity: 0;
      transition: opacity $base-animation;
    }

    &--draggable {
      width: calc(100% - #{$sidebar-item-draggable-element-width});
      margin-left: $sidebar-item-draggable-element-width;
      position: relative;
      cursor: move;
      background: none;
      color: $esmp-sidebar-link-color;

      &:before {
        opacity: 1;
        visibility: visible;
      }

      &:hover {
        background: $esmp-sidebar-background-color;
        color: $esmp-sidebar-link-color;
      }
    }
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: $esmp-sidebar-link-active-background-color;
  }
}
